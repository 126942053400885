import {
  AdBanner,
  AdLogo,
  AdvContainer,
  AdvWrapper,
  Layout,
  LogoWrapper,
} from "../../../style/Home/RecommendSection.module";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import { client } from "../../../client";
import { BannerClicked } from "./Ad.module";
import { GET_AD_BANNER } from "../../admin-ad/Ad.gql";

const LogType = {
  AD: "AD",
  GENERAL_LOG: "GENERAL_LOG",
  TEST: "TEST",
};

export const MainAdv = () => {
  const boxRef = useRef([]);
  const [items, setItems] = useState([
    {
      key: 1,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/bs1.gif",
    },
    {
      key: 2,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/bs2.gif",
    },
    {
      key: 3,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/bs3.gif",
    },
    {
      key: 4,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/bs4.gif",
    },
    {
      key: 5,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/bs5.gif",
    },
  ]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await client.query({
          query: GET_AD_BANNER,
          variables: {
            visible: true,
          },
        });

        const tempAds = data.getAdBannerList
          .filter((ad) => ad.account_id === 10) // accountId가 1인 요소로 필터링
          .map((ad, idx) => {
            return {
              ...ad,
              key: idx,
            };
          });

        console.log("tempAds...", tempAds);

        setItems(tempAds);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);

  // // View Log
  const [bannerIndex, setBannerIndex] = useState(items[0]?.key);

  // useEffect(() => {
  //   const checkItems = () => {
  //     if (items.length > 0) {
  //       createObserver(bannerIndex, items, boxRef);
  //     } else {
  //       setTimeout(checkItems, 50);
  //     }
  //   };

  //   checkItems();
  // }, [bannerIndex]);

  const handleCarouselChange = (index) => {
    setBannerIndex(index);
  };

  return (
    <Layout>
      <AdvContainer>
        <Carousel
          interval={5000}
          onChange={(index) => handleCarouselChange(index)}
        >
          {items.map((item, i) => (
            <AdvWrapper key={i} item={item}>
              <AdBanner
                key={item.key}
                src={item.image_url}
                alt={item.name}
                onClick={() => {
                  BannerClicked(item);
            
                  try {
                    const currentUrl = new URL(window.location.href); // 현재 URL 주소 파싱
                    const companyId = parseInt(
                      currentUrl.searchParams.get("companyId"),
                      10
                    );

                    const targetPath =
                      companyId === 10
                        ? "/whisky365?companyId=10"
                        : "/whisky365"; // 기본 경로

                    // 현재 URL의 도메인을 유지하며 경로 변경
                    window.location.href = `${window.location.origin}${targetPath}`;
                  } catch (error) {
                    console.error("Invalid URL:", window.location.href);
                    window.location.pathname = "/whisky365"; // 기본 경로로 fallback
                  }
                }}
                ref={(ref) => (boxRef.current[i] = ref)}
              />
            </AdvWrapper>
          ))}
        </Carousel>
      </AdvContainer>
    </Layout>
  );
};

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import {
  LoginInputContainer,
  LoginButtinContainer,
} from "../../../style/Login.module";
import { useLocation, useNavigate } from "react-router-dom";
import AgreeModal from "../../../component/sign-up/AgreeModal";
import { client } from "../../../client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password)
  }
`;

const CenteredContainer = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // 화면 전체 높이로 설정
        backgroundColor: "#f0f0f0", // 전체 배경 색상
        margin: 0, // 마진 제거
        padding: 0, // 패딩 제거
      }}
    >
      {children}
    </div>
  );
};

export const LoginBsdf = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsLoggedin(true);
      navigate("/");
    }
  }, []);

  const handleLogin = () => {
    client
      .mutate({
        mutation: LOGIN,
        variables: {
          email: email,
          password: password,
        },
      })
      .then((result) => {
        console.log(result.data);
        alert("로그인성공");
        const accessToken = result.data.login;
        localStorage.setItem("accessToken", accessToken);
        const from = location.state?.from?.pathname || "/";
        navigate(from);
      })
      .catch((error) => {
        alert(error);
        console.log(error);
      });
  };

  const handleNaverLogin = () => {
    toast.error("준비중인 기능입니다. 일반 로그인 및 회원가입을 이용해주세요.");
    // alert("준비중인 기능입니다. 일반 로그인 및 회원가입을 이용해주세요.");
    // const naverLoginUrl = "http://localhost:4001/auth/naver/callback";
    // window.location.href = naverLoginUrl;
  };

  const handleKakaoLogin = () => {
    toast.error("준비중인 기능입니다. 일반 로그인 및 회원가입을 이용해주세요.");
    // alert("준비중인 기능입니다. 일반 로그인 및 회원가입을 이용해주세요.");
    // const Rest_api_key = "d2020bbf7b3cad61268df5b704d99445"; //REST API KEY
    // const redirect_uri = `${process.env.REACT_APP_API_URL.replace(
    //   /graphql\/?$/,
    //   ""
    // )}auth/kakao/callback`;
    // console.log("redirecturi...", redirect_uri);

    // const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    // window.location.href = kakaoURL;
  };

  const handleAgree = () => {
    navigate("/signupbsdf");
  };

  const [open, setOpen] = useState(false);
  const handleSignUp = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CenteredContainer>
      <div
        style={{
          maxWidth: "400px",
          width: "100%",
          backgroundColor: "#fff", // 로그인 박스 배경 색상
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 0, // 마진 제거
          paddingTop: "2rem", // 패딩 제거
          height: "100vh", // 화면 전체 높이로 설정
        }}
      >
        <h1> 로그인 </h1>

        <LoginInputContainer>
          <TextField
            fullWidth
            label="Email"
            value={email}
            sx={{ width: "100%" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            sx={{ marginTop: "1rem" }}
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div
            style={{
              marginTop: "0.5rem",
              fontSize: "0.8rem",
              color: "#888",
            }}
          >
            성인인증 및 구매를 위하여 로그인해주세요.
          </div>
        </LoginInputContainer>
        <LoginButtinContainer>
          <Button
            sx={{ width: "100%", backgroundColor: "#888" }}
            variant="contained"
            onClick={handleLogin}
          >
            {" "}
            일반 로그인{" "}
          </Button>
          {/* <Button
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              backgroundColor: "#fee102",
              color: "#000000",
              "&:hover": {
                backgroundColor: "#fee10280",
              },
            }}
            variant="contained"
            onClick={handleKakaoLogin}
          >
            {" "}
            카카오 로그인{" "}
          </Button>

          <Button
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              backgroundColor: "#03C75A",
              color: "#000000",
              "&:hover": {
                backgroundColor: "#05D67C",
              },
            }}
            variant="contained"
            onClick={handleNaverLogin}
          >
            {" "}
            네이버 로그인{" "}
          </Button> */}

          <Button
            sx={{
              width: "100%",
              marginTop: "2rem",
              backgroundColor: "#000000",
              "&:hover": {
                backgroundColor: "#00000080",
              },
            }}
            variant="contained"
            onClick={handleSignUp}
          >
            회원가입
          </Button>
          <Button
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              backgroundColor: "transparent", // Set background to transparent
              color: "#000000", // Set text color to black
              border: "1px solid #000000", // Optional: Add a border to make it stand out
              "&:hover": {
                backgroundColor: "#00000010", // Lighten the hover effect (optional)
              },
            }}
            variant="outlined" // Use outlined variant for a better look with transparent background
            onClick={() => {
              navigate("/findPassword");
            }}
          >
            비밀번호 찾기
          </Button>
        </LoginButtinContainer>
        <AgreeModal
          open={open}
          handleClose={handleClose}
          handleAgree={handleAgree}
        />
      </div>
      <ToastContainer />
    </CenteredContainer>
  );
};

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { HomeContainer, HomeInnerContainer } from "../style/Home/Home.module";
import { TopMenu } from "../component/home/TopMenu";
import { HomeBanner } from "../component/home/HomeBanner";
import { BottomBanner } from "../component/common/BottomBanner";
import { SearchInput } from "../component/home/SearchInput";
import { RecommendSection } from "../component/home/RecommendSection";
import styled from "styled-components";
import { MainCategorySection } from "../component/home/MainCategorySection";
import { MainAdv } from "../component/home/Adv/MainAdv";
import BottomAd from "../component/ad-banner/BottomAd";
import { Footer } from "../component/common/Footer";
import { useNavigate } from "react-router-dom";
import { SubAdv } from "../component/home/Adv/SubAdv";
import { BannerAdv } from "../component/home/Adv/BannerAdv";
import { FloatingButton } from "../component/home/HomeFloatingButton";

export const HomeV4 = () => {
  const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  const AdContainer = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    @media (max-width: 400px) {
      min-width: 360px; /* 400px 미만인 경우 */
      width: 100%; /* 400px 이상인 경우 */
    }
  `;

  //kakao share
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const shareBtn = () => {
    // alert('카카오 공유')
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        const kakao = window.Kakao;
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }

      const kakao = window.Kakao;

      const shareLink = "https://dutyfree-price.com/";
      // 카카오 링크 공유 설정
      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입
        content: {
          title: "국내 면세점 최저가", // 인자값으로 받은 title
          description: '"깜짝 놀랄 가격!! 세상에 이 가격이 맞아!! "', // 인자값으로 받은 title
          imageUrl:
            "https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_share_banner.png",
          link: {
            mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
            webUrl: shareLink,
          },
        },
        buttons: [
          {
            title: "면세점 가격비교 템 쏜다",
            link: {
              mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
              webUrl: shareLink,
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };

  // 디코딩해서 id를 확인합니다
  let isTest = false;
  try {
    const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
    isTest = decodedToken.id === 251 || decodedToken.id === 277;
  } catch (error) {
    console.error("토큰 디코딩 오류:", error);
  }

  const navigate = useNavigate();
  useEffect(() => {
    // Session Storage에 companyId가 있는지 확인하여 리다이렉트
    const companyId = sessionStorage.getItem("companyId");

    if (companyId && companyId != 1) {
      navigate("/whisky365?companyId=" + companyId);
    }
  }, []);

  // AiaFloatingButtonContainer 클릭시 로그인 상태면 /aiappCoupon, 로그아웃 상태면 /login으로 이동
  const onClickAiaFloatingButton = () => {
    // 로그인 상태면 (accessToken 으로 체크한다.)
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      alert("쿠폰 페이지로 이동합니다.");
      navigate("/eventCoupon");
    } else {
      // alert("로그인 후 이용해주세요.");
      navigate("/event1");
    }
  };

  useEffect(() => {
    const generateSessionId = () => {
      const characters = "0123456789";

      return Array.from(
        { length: 5 },
        () => characters[Math.floor(Math.random() * characters.length)]
      ).join("");
    };

    const getSessionId = () => {
      let sessionId = sessionStorage.getItem("sessionId");

      if (!sessionId) {
        sessionId = generateSessionId();
        sessionStorage.setItem("sessionId", sessionId);
      }

      return sessionId;
    };

    getSessionId();
  }, []);

  return (
    <HomeContainer>
      <CenterContainer>
        <HomeInnerContainer>
          <TopMenu></TopMenu>
          <HomeBanner> </HomeBanner>
          <SearchInput></SearchInput>
          {isTest ? (
            <a
              href="https://busandf.com/member_process/niceid_phone_return?intro=Y&return_url=/goods/view?no=31760&EncodeData=AgAFQ0U1MzNMhIdj5i4fIhIpxqB4a6g4j80%2BW1FKMjrS6MpPBlEzszdLdEGgsiyVXT9QK/YupH3vRkT2GEOGf4F0vdyi5obvrX2%2BaEHTDnbn7o2lmsvF6EJ13R2u6UHeCEp87Yv0WJ45xaBuW%2BXCV%2B6FEDiMvWEBqdBRXDzitgbvCzM0hXevtQdivgmBODtVa%2BxBZTy3KZE3w5zljaZMA/uRXEmKDSnv2yfAJIAMwse%2Br6RuScD6dXID7wY3keu9nWiuRAgjAqaKgN1NAnr0ZPSm5iY9Kc%2Brs%2BgH78yXvxC14/4c%2BhgobP%2BDvyRPbqCcUIpDPCs6uRhuxR67Tj2dtC1RykXfpAaiE1dhPwz0Mj9JCo7VdrJ4FCC6KbYxwt3xGuJVCadBIdF38CEo3abSq9cfgezXLxhNHQkU7nPBk2ebe6K/dl0JeE0k0os5FfSl6A19sAetd5frJvotldtwMO/sIC1tiIuCU3zGVO0F5ls77XhGtICNGnuxiuI%2BTxckW2ZFqZuibRZTLmeX4U1gfeah34U37pJpuIPgsYvNUiTDRIHII2fi4FQSaU2uwk9Gy0NtcohJHwqWcoa5ML428SlbH7CpPuvQ"
              style={{
                cursor: "pointer",
                color: "black",
                backgroundColor: "white",
              }}
            >
              <Button
                variant="primary"
                style={{
                  backgroundColor: "blue",
                  borderColor: "blue",
                  color: "white",
                }}
              >
                이동 가능한 링크
              </Button>
            </a>
          ) : null}

          <MainAdv></MainAdv>
          <MainCategorySection></MainCategorySection>

          <SubAdv></SubAdv>
          <RecommendSection></RecommendSection>
          <BottomBanner></BottomBanner>

        </HomeInnerContainer>
        <AdContainer>
          <BottomAd></BottomAd>
        </AdContainer>
        <Footer></Footer>

        <FloatingButton
          bottom="125px"
          onClick={() => shareBtn()}
          imgSrc="https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_share.png"
        />
        <FloatingButton
          bottom="65px"
          onClick={() => onClickAiaFloatingButton()}
          imgSrc="https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_coupon.png"
        />
        <FloatingButton
          bottom="5px"
          link="http://pf.kakao.com/_RjKPG"
          imgSrc="https://dfx-img.s3.ap-northeast-2.amazonaws.com/kakaodfp.png"
        />
      </CenterContainer>
    </HomeContainer>
  );
};

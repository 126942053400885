import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import styled from "styled-components";
import { client } from "../../../client";
import { GET_AD_BANNER } from "../../admin-ad/Ad.gql";

// 스타일 컴포넌트 정의
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  width: 84%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  background: #fff; /* 흰색 배경 */
  border: none;
  border-radius: 90%; /* 둥근 버튼 */
  font-size: 24px;
  cursor: pointer;
  color: #000;
  z-index: 10; /* 항상 보이도록 설정 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 부드러운 검정 그림자 */
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.2s, transform 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25); /* 호버 시 그림자 살짝 강조 */
    transform: scale(1.05); /* 호버 시 살짝 확대 */
  }

  &:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15); /* 클릭 시 그림자 약화 */
    transform: scale(0.97); /* 클릭 시 살짝 축소 */
  }
`;

const AdBanner = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const AdvWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerAdv = () => {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await client.query({
          query: GET_AD_BANNER,
          variables: {
            visible: true,
          },
        });

        const filteredAds = data.getAdBannerList
          .filter((ad) => ad.account_id === 11) // accountId가 11인 광고 필터링
          .slice(0, 3) // 최대 3개의 이미지만 사용
          .map((ad, idx) => ({
            key: idx,
            ...ad,
          }));

        setItems(filteredAds);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);

  const handleBannerClick = (item) => {
    try {
      const targetPath = "/whisky365"; // 기본 경로 설정
      window.location.href = `${window.location.origin}${targetPath}`;
    } catch (error) {
      console.error("Invalid URL:", window.location.href);
      window.location.pathname = "/defaultPath"; // fallback 경로
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen || items.length === 0) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <Carousel
          interval={5000}
          navButtonsAlwaysVisible
          animation="slide" // 슬라이드 애니메이션 적용
        >
          {items.map((item) => (
            <AdvWrapper key={item.key}>
              <AdBanner
                src={item.image_url}
                alt={item.name}
                onClick={() => handleBannerClick(item)}
              />
            </AdvWrapper>
          ))}
        </Carousel>
      </ModalContainer>
    </ModalOverlay>
  );
};
